var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomDialog',{ref:"dialog",attrs:{"title":"Event Type","open":_vm.open,"button2-text":_vm.isEditDialog ? 'Update' : 'Save',"max-width":600,"main-btn-disabled":_vm.can_save === false},on:{"update:open":function($event){_vm.open=$event},"button1":_vm.cancel,"button2":_vm.save,"click-close":_vm.cancel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"mt-2"},[(!_vm.isEditDialog)?[_c('v-row',{staticClass:"flex-nowrap mb-2",attrs:{"dense":"","align":"start","justify":"start"}},[_c('v-text-field',{staticClass:"mx-1",attrs:{"outlined":"","rules":[_vm.requiredRule()],"hide-details":"auto","placeholder":"Enter event type name"},model:{value:(_vm.type.name),callback:function ($$v) {_vm.$set(_vm.type, "name", $$v)},expression:"type.name"}}),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":_vm.type.color,"dark":"","height":"50"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#ffffff"}},[_vm._v("mdi-format-color-fill")])],1)]}}],null,false,4015453620)},[_c('v-card',{attrs:{"flat":"","width":"380"}},[_c('v-card-text',{staticClass:"pa-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.available_colors),function(color,index){return _c('v-btn',{key:index,staticClass:"ma-1",attrs:{"depressed":"","tile":"","height":"50","color":color,"dark":""},on:{"click":function($event){_vm.type.color = color}}},[_c('v-icon',{attrs:{"color":"#ffffff"}},[_vm._v("mdi-format-color-fill")])],1)}),_c('v-col',{staticClass:"ma-1"},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"outlined":"","text":"","tile":"","width":"135","height":"50"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"#000000"}},[_vm._v(" mdi-format-color-highlight ")]),_c('span',{staticClass:"text-black"},[_vm._v("Custom")])],1)]}}],null,false,1188296030)},[_c('v-color-picker',{attrs:{"show-swatches":"","flat":"","mode":"hexa"},on:{"update:color":function($event){_vm.type.color = $event.hexa}}})],1)],1),_c('v-spacer')],2)],1)],1)],1),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"success","height":"50","disabled":!_vm.can_add},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)],1),_vm._l((_vm.types),function(item,index){return _c('v-row',{key:index,staticClass:"flex-nowrap mb-2",attrs:{"dense":"","align":"start","justify":"start"}},[_c('v-text-field',{staticClass:"mx-1 primary--text",attrs:{"height":"35","readonly":"","outlined":"","hide-details":"auto","label":"Name *","placeholder":"Untitle"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('v-btn',{staticClass:"mx-1",attrs:{"width":"50","depressed":"","height":"50","color":item.color,"dark":""}},[_c('v-icon',{attrs:{"color":"#ffffff"}},[_vm._v("mdi-format-color-fill")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"dark":"","color":"red","height":"50"},on:{"click":function($event){return _vm.remove(index)}}},[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)],1)})]:[_c('v-row',{attrs:{"no-gutters":"","align":"start","justify":"start"}},[_c('v-text-field',{staticClass:"mx-1",attrs:{"counter":"50","height":"35","outlined":"","hide-details":"auto","label":"Name *","placeholder":"Enter event type name"},model:{value:(_vm.type.name),callback:function ($$v) {_vm.$set(_vm.type, "name", $$v)},expression:"type.name"}}),_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"height":"50","color":_vm.type.color,"dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#ffffff"}},[_vm._v("mdi-format-color-fill")])],1)]}}])},[_c('v-card',{attrs:{"flat":"","width":"380"}},[_c('v-card-text',{staticClass:"pa-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.available_colors),function(color,index){return _c('v-btn',{key:index,staticClass:"ma-1",attrs:{"width":"50","depressed":"","tile":"","height":"50","color":color,"dark":""},on:{"click":function($event){_vm.type.color = color}}},[_c('v-icon',{attrs:{"color":"#ffffff"}},[_vm._v("mdi-format-color-fill")])],1)}),_c('v-col',{staticClass:"ma-1"},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"outlined":"","text":"","tile":"","width":"135","height":"50"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","color":"#000000"}},[_vm._v(" mdi-format-color-highlight ")]),_c('span',{staticClass:"text-black"},[_vm._v("Custom")])],1)]}}])},[_c('v-color-picker',{attrs:{"show-swatches":"","flat":"","mode":"hexa"},on:{"update:color":function($event){_vm.type.color = $event.hexa}}})],1)],1),_c('v-spacer')],2)],1)],1)],1)],1)]],2)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }