<template>
  <CustomDialog
    title="Event Type"
    ref="dialog"
    :open.sync="open"
    :button2-text="isEditDialog ? 'Update' : 'Save'"
    @button1="cancel"
    @button2="save"
    @click-close="cancel"
    :max-width="600"
    :main-btn-disabled="can_save === false"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text class="mt-2">
          <template v-if="!isEditDialog">
            <v-row class="flex-nowrap mb-2" dense align="start" justify="start">
              <v-text-field
                outlined
                :rules="[requiredRule()]"
                hide-details="auto"
                placeholder="Enter event type name"
                v-model="type.name"
                class="mx-1"
              />
              <v-menu bottom offset-y left :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-1"
                    :color="type.color"
                    dark
                    height="50"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#ffffff">mdi-format-color-fill</v-icon>
                  </v-btn>
                </template>
                <v-card flat width="380">
                  <v-card-text class="pa-2">
                    <v-row no-gutters>
                      <v-btn
                        class="ma-1"
                        v-for="(color, index) in available_colors"
                        :key="index"
                        depressed
                        tile
                        height="50"
                        :color="color"
                        dark
                        @click="type.color = color"
                      >
                        <v-icon color="#ffffff">mdi-format-color-fill</v-icon>
                      </v-btn>
                      <v-col class="ma-1">
                        <v-menu :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="text-none"
                              outlined
                              text
                              tile
                              width="135"
                              height="50"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left color="#000000">
                                mdi-format-color-highlight
                              </v-icon>
                              <span class="text-black">Custom</span>
                            </v-btn>
                          </template>
                          <v-color-picker
                            show-swatches
                            flat
                            @update:color="type.color = $event.hexa"
                            mode="hexa"
                          />
                        </v-menu>
                      </v-col>
                      <v-spacer />
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-btn
                color="success"
                class="mx-1"
                height="50"
                :disabled="!can_add"
                @click="add"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-row>

            <v-row
              dense
              align="start"
              justify="start"
              class="flex-nowrap mb-2"
              v-for="(item, index) in types"
              :key="index"
            >
              <v-text-field
                height="35"
                readonly
                outlined
                hide-details="auto"
                label="Name *"
                placeholder="Untitle"
                v-model="item.name"
                class="mx-1 primary--text"
              />
              <v-btn
                width="50"
                depressed
                height="50"
                class="mx-1"
                :color="item.color"
                dark
              >
                <v-icon color="#ffffff">mdi-format-color-fill</v-icon>
              </v-btn>
              <v-btn
                dark
                color="red"
                height="50"
                class="mx-1"
                @click="remove(index)"
              >
                <v-icon>mdi-delete-circle-outline</v-icon>
              </v-btn>
            </v-row>
          </template>
          <template v-else>
            <v-row no-gutters align="start" justify="start">
              <v-text-field
                counter="50"
                height="35"
                outlined
                hide-details="auto"
                label="Name *"
                placeholder="Enter event type name"
                v-model="type.name"
                class="mx-1"
              />
              <v-menu bottom offset-y left :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    height="50"
                    class="mx-1"
                    :color="type.color"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#ffffff">mdi-format-color-fill</v-icon>
                  </v-btn>
                </template>

                <v-card flat width="380">
                  <v-card-text class="pa-2">
                    <v-row no-gutters>
                      <v-btn
                        class="ma-1"
                        v-for="(color, index) in available_colors"
                        :key="index"
                        width="50"
                        depressed
                        tile
                        height="50"
                        :color="color"
                        dark
                        @click="type.color = color"
                      >
                        <v-icon color="#ffffff">mdi-format-color-fill</v-icon>
                      </v-btn>
                      <v-col class="ma-1">
                        <v-menu :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="text-none"
                              outlined
                              text
                              tile
                              width="135"
                              height="50"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left color="#000000">
                                mdi-format-color-highlight
                              </v-icon>
                              <span class="text-black">Custom</span>
                            </v-btn>
                          </template>
                          <v-color-picker
                            show-swatches
                            flat
                            @update:color="type.color = $event.hexa"
                            mode="hexa"
                          />
                        </v-menu>
                      </v-col>
                      <v-spacer />
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import _diff from 'lodash/difference'
import _uniq from 'lodash/uniq'

export default {
  name: 'EventCategoryDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    editType: Object,
    isEditDialog: { type: Boolean, default: false }
  },
  data: () => ({
    open: false,
    type: {
      color: '#0000FF',
      name: null
    },
    types: [],
    preset_color: [
      '#FF0000',
      '#0000FF',
      '#FFFF00',
      '#008000',
      '#FFA500',
      '#EE82EE',
      '#FFC0CB',
      '#9ACD32',
      '#87ceeb',
      '#A52A2A',
      '#800080'
    ]
  }),
  computed: {
    ...mapGetters('calendar', ['categories']),
    can_save() {
      return this.isEditDialog
        ? !!(this.type.color && this.type.name)
        : this.types.length > 0
    },
    can_add() {
      return !!(this.type.name && this.type.color)
    },
    existing_colors() {
      if (!this.categories) return []
      return this.categories.map((item) => {
        return item.properties.color
      })
    },
    available_colors() {
      return _uniq(_diff(this.existing_colors, this.preset_color))
    }
  },
  watch: {
    value(new_val) {
      this.open = new_val
      this.types = []
    },
    open(new_val) {
      this.$emit('input', new_val)
    },
    editType: {
      handler(val) {
        if (val) {
          this.type = {
            name: val.name,
            color: val.color
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save() {
      if (this.isEditDialog) {
        this.$emit('update', {
          id: this.editType.id,
          name: this.type.name,
          color: this.type.color
        })
      } else {
        this.$emit('save', { types: this.types })
      }
    },
    cancel() {},
    add() {
      this.types.unshift(_cloneDeep(this.type))
      this.type = {
        color: '#0000FF',
        name: null
      }
    },
    remove(index) {
      this.types.splice(index, 1)
    }
  }
}
</script>

<style scoped></style>
