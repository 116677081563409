<template>
  <v-tooltip color="primary" top>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="
          fullwidth
          d-flex
          overflow-x-hidden
          rounded-5
          align-center
          justify-center
          flex-wrap
          cursor-pointer
        "
      >
        <v-icon v-if="timeText" x-small left :color="item.backgroundColor">
          mdi-calendar-clock
        </v-icon>
        <b class="fs-12 fw-500 ls-unset" v-if="timeText">
          {{ timeText }}
        </b>
        <span
          class="fw-500"
          :class="[timeText ? 'dark--text ml-1' : 'light--text']"
        >
          {{ item.title | trunc_mid(20, 5) }}
        </span>
      </div>
    </template>
    <span class="white--text">{{ item.title }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    timeText: { type: String, default: '' },
    item: { type: Object }
  }
}
</script>

<style></style>
