<template>
  <v-menu
    v-model="selectedOpen"
    :close-on-content-click="false"
    :position-x="positionX"
    :position-y="positionY"
    absolute
    offset-x
    :key="selectedOpen"
    :min-width="mdAndUp ? 400 : '100%'"
    max-width="450"
  >
    <v-card
      v-if="activeEvent"
      flat
      class="card-menu"
      :color="activeEvent.backgroundColor"
      :key="activeEvent.id"
    >
      <v-card-title
        class="py-2 primary d-flex align-center justify-space-between light--text"
      >
        <h4>{{ activeEvent.title | ucwords }}</h4>
        <div>
          <HelpToolTip
            v-if="is_creator && !activeEvent.extendedProps.active_recurrence"
            @click:icon="$emit('toggleRecurrence', 'resume')"
            icon-class="ml-1"
            text="Resume recurrence of event (Will continue creating new events align to this event)"
            icon-color="white"
            icon="mdi-motion-play"
          ></HelpToolTip>
          <HelpToolTip
            v-if="is_creator && activeEvent.extendedProps.active_recurrence"
            @click:icon="$emit('toggleRecurrence', 'terminate')"
            icon-class="ml-1"
            text="Pause recurrence of event (Will no longer create new events from this recurrence)"
            icon-color="white"
            icon="mdi-motion-pause"
          ></HelpToolTip>
          <HelpToolTip
            v-if="is_creator"
            @click:icon="$emit('showEditEvent')"
            icon-class="ml-1"
            text="Edit Event"
            icon-color="white"
            icon="mdi-circle-edit-outline"
          ></HelpToolTip>
          <HelpToolTip
            v-if="is_creator"
            @click:icon="$emit('showDeleteEvent')"
            icon-class="ml-1"
            text="Delete Event"
            icon-color="white"
            icon="mdi-delete-circle-outline"
          ></HelpToolTip>
          <HelpToolTip
            @click:icon="selectedOpen = false"
            icon-class="ml-1"
            text="Close"
            icon-color="white"
            icon="mdi-close-circle-outline"
          ></HelpToolTip>
        </div>
      </v-card-title>
      <v-card-text class="text-left card-menu-body">
        <v-row dense class="fill-height" align="center" justify="center">
          <v-col cols="8" class="py-5">
            <v-row dense align="center" justify="start">
              <v-col
                cols="4"
                v-if="activeEvent.extendedProps.participants.length"
                class="light--text fs-13 text-right fw-700"
              >
                Participants :
              </v-col>
              <v-col
                cols="8"
                class="text-left"
                v-if="activeEvent.extendedProps.participants.length"
              >
                <Avatars
                  :center="false"
                  :count="4"
                  :items="activeEvent.extendedProps.participants"
                ></Avatars>
              </v-col>
              <v-col
                cols="4"
                v-if="activeEvent.extendedProps.leads.length"
                class="light--text fs-13 text-right fw-700"
              >
                Leads :
              </v-col>
              <v-col
                cols="8"
                class="text-left"
                v-if="activeEvent.extendedProps.leads.length"
              >
                <Avatars
                  :center="false"
                  :count="4"
                  :items="activeEvent.extendedProps.leads"
                ></Avatars>
              </v-col>
              <v-col cols="4" class="light--text fs-13 text-right fw-700">
                Event Type :
              </v-col>
              <v-col cols="8" class="light--text text-left">
                {{ activeEvent.extendedProps.event_type.name | ucwords }}
              </v-col>
              <v-col cols="4" class="light--text fs-13 text-right fw-700">
                Repeated :
              </v-col>
              <v-col cols="8" class="light--text text-left">
                {{ activeEvent.extendedProps.recurrence | ucwords }}
              </v-col>
              <v-col cols="4" class="light--text fs-13 text-right fw-700">
                Event Time :
              </v-col>
              <v-col cols="8" class="light--text">
                {{ activeEvent.extendedProps.event_duration }}
              </v-col>
              <v-col
                v-if="activeEvent.extendedProps.description"
                cols="4"
                class="light--text caption fw-700"
              >
                Description :
              </v-col>
              <v-col
                v-if="activeEvent.extendedProps.description"
                cols="8"
                class="light--text"
                v-text="activeEvent.extendedProps.description"
              ></v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-img
              contain
              :lazy-src="settings.loader"
              max-height="150"
              :src="info_image"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="transparent light--text">
        <v-btn
          depressed
          color="white"
          small
          class="text-none px-5 primary--text"
          v-if="is_creator"
          @click="$emit('invite')"
        >
          Invite
        </v-btn>
        <v-btn
          v-else
          @click="$emit('showLeaveEvent')"
          color="white"
          small
          class="text-none px-5 primary--text"
        >
          Leave Event
        </v-btn>
        <v-btn
          v-if="activeEvent.extendedProps.url"
          depressed
          small
          color="white"
          class="text-none px-5 primary--text"
          target="_blank"
          :to="activeEvent.extendedProps.url"
        >
          Open Link
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CalendarPopupView',
  props: {
    value: { type: Boolean, default: false },
    activeEvent: { type: Object },
    positionX: { type: Number, default: 0 },
    positionY: { type: Number, default: 0 }
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedOpen = val
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    is_creator() {
      return (
        this.activeEvent &&
        this.activeEvent.extendedProps.creator == this.user.id
      )
    }
  },
  data: () => ({
    selectedOpen: false,
    info_image: require('@/assets/file-icons/defaults/calendar.svg').default
  })
}
</script>

<style></style>
