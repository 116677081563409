<template>
  <v-navigation-drawer
    :width="smAndDown ? '100%' : 470"
    right
    v-model="open"
    :clipped="true"
    :floating="true"
    :permanent="false"
    :temporary="true"
    app
    class="rounded-0 above-overlay"
    overflow
  >
    <template v-slot:prepend>
      <v-list-item class="primary">
        <v-list-item-icon>
          <v-icon @click="close" color="white"> mdi-close </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="light--text">
            {{ title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <v-card flat>
      <v-form ref="form" v-model="isFormValid">
        <v-card-text>
          <v-row dense>
            <v-col
              sm="12"
              md="6"
              class="d-flex align-center justify-center flex-nowrap"
            >
              <HelpToolTip
                icon-size="20"
                text="If set, It will notify participants 15 minutes before the
                      start of event."
              ></HelpToolTip>
              <v-checkbox
                dense
                hide-details="auto"
                label="Alarm event?"
                v-model="event.alarm"
                class="my-0 checkbox-primary--text"
              >
              </v-checkbox>
            </v-col>
            <v-col
              sm="12"
              md="6"
              class="d-flex align-center justify-center flex-nowrap"
            >
              <HelpToolTip
                icon-size="20"
                text="All day events doesn't requires setting of time"
              ></HelpToolTip>
              <v-checkbox
                dense
                hide-details="auto"
                label="All day event?"
                v-model="event.allDay"
                class="my-0 checkbox-primary--text"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <label class="subtitle-2 d-block primary--text">
                Event Type (Required)
              </label>
              <v-select
                :items="categories"
                :menu-props="{ top: true, offsetY: true }"
                @click:append="$emit('open-custom-event-type')"
                append-icon="mdi-plus-circle-outline"
                item-text="name"
                item-value="id"
                outlined
                hide-details="auto"
                dense
                :rules="requiredRules"
                v-model="event.event_type"
              >
                <template v-slot:selection="data">
                  <v-chip class="border-0" label outlined v-bind="data.attrs">
                    <v-icon size="20" :color="data.item.color" left>
                      mdi-checkbox-blank-circle
                    </v-icon>
                    <span>{{ data.item.name }}</span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item: myItem }">
                  <span>
                    <v-icon :color="myItem.color" left>mdi-circle</v-icon>
                    {{ myItem.name }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="mb-2">
            <v-col cols="12" sm="12" md="12">
              <label class="subtitle-2 d-block primary--text">
                {{
                  event.allDay
                    ? 'Start Date (Required)'
                    : 'Start Date & Time (Required)'
                }}
              </label>
              <KirbyDateTimePicker
                v-model="event.start"
                :type="event.allDay ? 'date' : 'datetime'"
                :min-date="today"
                name="start"
                :rules="[requiredRule()]"
              ></KirbyDateTimePicker>
            </v-col>
            <v-col sm="12" md="12">
              <label class="subtitle-2 d-block primary--text">
                {{
                  event.allDay
                    ? 'End Date (Required)'
                    : 'End Date & Time (Required)'
                }}
              </label>
              <KirbyDateTimePicker
                v-model="event.end"
                name="end"
                :rules="[requiredRule()]"
                :type="event.allDay ? 'date' : 'datetime'"
                :disabled="end_datetime_is_disabled"
                :min-date="event.start"
              ></KirbyDateTimePicker>
            </v-col>
            <v-col cols="12" sm="12" md="12" v-if="!isEdit">
              <label class="subtitle-2 d-block primary--text">
                Recurrence (Required)
              </label>
              <v-select
                item-text="text"
                item-value="value"
                :items="recurrences"
                hide-details="auto"
                v-model="event.recurrence"
                :rules="[requiredRule()]"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <label class="subtitle-2 d-block primary--text">
                Event Title (Required)
              </label>
              <v-text-field
                outlined
                dense
                :rules="requiredRules"
                hide-details="auto"
                v-model.trim="event.title"
              />
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 d-block primary--text">
                Event Link (Optional)
              </label>
              <v-text-field
                clear-icon="mdi-close-circle-outline"
                clearable
                outlined
                dense
                hide-details="auto"
                placeholder="https://"
                v-model.trim="event.link"
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      A link where participants can join. e.g Skype link, Zoom
                      Link or a Google Meet link
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <label class="subtitle-2 d-block primary--text">
                Project (Optional)
              </label>
              <v-select
                :items="projects"
                :menu-props="{ top: true, offsetY: true }"
                :multiple="false"
                @click:clear="event.project = null"
                clear-icon="mdi-close-circle-outline"
                clearable
                outlined
                dense
                no-data-text="No Projects found!"
                hide-details="auto"
                item-text="normalize_title"
                item-value="id"
                chips
                small-chips
                v-model="event.project"
              >
                <template
                  v-slot:selection="{ attr, on, item: myItem, selected }"
                >
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    label
                    small
                    outlined
                    clearable
                    v-on="on"
                  >
                    <span v-text="myItem.project_name"></span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item: myItem }">
                  <span>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ myItem.project_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span>{{ myItem.type | ucwords }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 d-block primary--text">
                Service (Optional)
              </label>
              <v-select
                :items="services"
                :menu-props="{ top: true, offsetY: true }"
                :multiple="false"
                @click:clear="event.service = null"
                clear-icon="mdi-close-circle-outline"
                clearable
                outlined
                dense
                no-data-text="No Service found!"
                hide-details="auto"
                item-text="name"
                item-value="id"
                chips
                v-model="event.service"
              >
                <template
                  v-slot:selection="{ attr, on, item: item1, selected }"
                >
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    label
                    small
                    outlined
                    clearable
                    v-on="on"
                  >
                    <span v-text="item1.name"></span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <label class="subtitle-2 d-block primary--text">
                Event Participants (Optional)
              </label>
              <v-autocomplete
                :items="members.items"
                chips
                deletable-chips
                clear-icon="mdi-close-circle-outline"
                clearable
                outlined
                dense
                hide-details="auto"
                item-text="fullname"
                item-value="id"
                multiple
                v-model="event.participants"
              >
                <template
                  v-slot:selection="{ attr, on, item: item2, index, selected }"
                >
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    label
                    small
                    outlined
                    clearable
                    v-on="on"
                    v-if="index < 1"
                  >
                    <span v-text="item2.fullname"></span>
                  </v-chip>
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    label
                    small
                    outlined
                    clearable
                    v-on="on"
                    v-if="index === 1"
                  >
                    <span> + {{ event.participants.length - 1 }} others </span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item: item3 }">
                  <template>
                    <v-list-item-avatar>
                      <img :src="item3.image_url" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="item3.fullname" />
                      <v-list-item-subtitle v-html="item3.job_title" />
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 d-block primary--text">
                Invite Leads (Optional)
              </label>
              <v-autocomplete
                v-model="event.leads"
                :items="search_items"
                :loading="isLoading"
                :search-input.sync="search_word"
                chips
                deletable-chips
                multiple
                clearable
                clear-icon="mdi-close-circle-outline"
                hide-details="auto"
                item-text="fullname"
                item-value="id"
                placeholder="Search for leads"
                outlined
                dense
                cache-items
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for
                      <strong>leads</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template
                  v-slot:selection="{ attr, on, item: item1, index, selected }"
                >
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    label
                    small
                    outlined
                    clearable
                    v-on="on"
                    v-if="index < 1"
                  >
                    <span v-text="item1.fullname"></span>
                  </v-chip>
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    label
                    small
                    outlined
                    clearable
                    v-on="on"
                    v-if="index === 1"
                  >
                    <span> + {{ event.leads.length - 1 }} others </span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item: item1 }">
                  <v-list-item-content :key="item1.id">
                    <v-list-item-title
                      v-text="item1.fullname"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item1.email || item1.contacts.input || ''"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col :col="smAndDown ? 12 : 6">
              <label class="subtitle-2 d-block primary--text">
                Event Description (Optional)
              </label>
              <v-textarea
                outlined
                auto-grow
                hide-details="auto"
                :rows="3"
                placeholder="Enter Description"
                v-model="event.description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
    <template v-slot:append>
      <v-row dense class="fullwidth pa-3" align="center" justify="center">
        <v-btn
          block
          @click="save"
          color="primary"
          class="text-none"
          :disabled="!can_save"
        >
          {{ isEdit ? 'Update Event' : 'Save Event' }}
        </v-btn>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import request from '@/services/axios_instance'
export default {
  name: 'EventRightSidebar',
  props: {
    value: { type: Boolean, default: true },
    item: { type: Object },
    eventPayload: Object
  },
  data: () => ({
    open: false,
    isFormValid: false,
    search_items: [],
    isLoading: false,
    search_word: null,
    event: {
      title: null,
      description: null,
      event_type: null,
      project: null,
      service: null,
      link: null,
      backgroundColor: '#2196f3',
      allDay: true,
      alarm: false,
      participants: [],
      leads: [],
      start: null,
      end: null,
      recurrence: 'none',
      timezone: moment.tz.guess()
    },
    default: {
      title: null,
      description: null,
      event_type: null,
      project: null,
      service: null,
      link: null,
      backgroundColor: '#2196f3',
      allDay: true,
      alarm: false,
      participants: [],
      start: null,
      end: null,
      recurrence: 'none',
      timezone: moment.tz.guess()
    },
    members: {
      original: [],
      items: []
    },
    today: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:00')
  }),
  watch: {
    item: {
      handler: function (val) {
        if (val && typeof val === 'object') {
          this.mapEvent(val)
        } else {
          this.event = _.cloneDeep(this.default)
        }
      },
      deep: true,
      immediate: true
    },
    search_word: _.debounce(function (val) {
      this.searchNow(val)
    }, 500),
    value(val) {
      this.open = val
      if (val && !this.isEdit) {
        this.resetEvent()
        this.resetValidation()
      }
      this.event.timezone = this.user_timezone
    },
    open(val) {
      this.$emit('input', val)
    },
    users: {
      handler: function (val) {
        this.members.original = val
          ? _.cloneDeep(val).filter((i) => i.id !== this.user.id)
          : []
        this.members.items = val
          ? _.cloneDeep(val).filter((i) => i.id !== this.user.id)
          : []
      },
      immediate: true,
      deep: true
    },
    eventPayload: {
      handler: function (payload) {
        if (payload) {
          this.event.allDay = payload.allDay
          this.event.event_type = payload.extendedProps
            ? payload.extendedProps.eventtypes_id
            : null
          if (payload.view.type === 'dayGridMonth') {
            /* "date": "2020-09-01T16:00:00.000Z", "dateStr": "2020-09-02", "allDay": true,*/ //click
            /* "start": "2020-09-07T16:00:00.000Z", "end": "2020-09-09T16:00:00.000Z", "startStr": "2020-09-08", "endStr": "2020-09-10", "allDay": true,*/ //drag_select
            if (payload.hasOwnProperty('dateStr')) {
              let date = moment.parseZone(payload.dateStr)
              this.event.start = date.clone().format('YYYY-MM-DD 00:00:00')
            }
            if (payload.hasOwnProperty('startStr')) {
              this.event.start = moment(payload.startStr, 'YYYY-MM-DD').format(
                'YYYY-MM-DD 00:00:00'
              )
            }
            if (payload.hasOwnProperty('endStr')) {
              this.event.end = moment(payload.endStr, 'YYYY-MM-DD').format(
                'YYYY-MM-DD 00:00:00'
              )
            }
          } else if (
            ['timeGridWeek', 'timeGridDay'].includes(payload.view.type)
          ) {
            /* "date": "2020-09-06T22:30:00.000Z", "dateStr": "2020-09-07T06:30:00+08:00", "allDay": false,*/ //click
            /* "start": "2020-09-05T22:00:00.000Z", "end": "2020-09-06T00:30:00.000Z", "startStr": "2020-09-06T06:00:00+08:00", "endStr": "2020-09-06T08:30:00+08:00", "allDay": false*/ //drag select
            if (payload.hasOwnProperty('dateStr')) {
              let date = moment.parseZone(payload.dateStr)
              this.event.start = date.clone().format('YYYY-MM-DD HH:mm:00')
            }
            if (payload.hasOwnProperty('startStr')) {
              let date1 = moment.parseZone(payload.startStr)
              this.event.start = date1.format('YYYY-MM-DD HH:mm:00')
            }
            if (payload.hasOwnProperty('endStr')) {
              let date2 = moment.parseZone(payload.endStr)
              this.event.end = date2.format('YYYY-MM-DD HH:mm:00')
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user', 'users']),
    ...mapGetters('calendar', ['categories', 'projects', 'services']),
    title() {
      return this.isEdit ? 'Update Event' : 'Add Event'
    },
    isEdit() {
      return this.item ? true : false
    },
    available() {
      return this.users.length > 0 ? _.cloneDeep(this.users) : []
    },
    end_datetime_is_disabled() {
      return !this.event.start ? true : false
    },
    recurrences() {
      return [
        { text: 'Doesn`t Repeat', value: 'none' },
        { text: 'Daily at same time', value: 'daily' },
        { text: 'Weekly at same day and time', value: 'weekly' },
        { text: 'Monthly at same day and time', value: 'monthly' }
      ]
    },
    user_timezone() {
      return moment.tz.guess() || 'UTC'
    },
    can_save() {
      return this.validEvent(false) && this.isFormValid
    },
    getPayload() {
      let payload = this.event
      // payload.recurrence = 'none'
      payload.timezone = this.user_timezone
      payload.groupId = moment(payload.start, 'YYYY-MM-DD HH:mm:ss').format(
        'YYYYMMDD'
      )
      payload.project_id = payload.project
      payload.service_id = payload.service
      if (this.isEdit) payload.id = this.item.id
      else payload.id = moment().unix()
      return payload
    }
  },
  methods: {
    close() {
      this.open = false
    },
    searchNow(keyword) {
      if (
        !this.isLoading &&
        keyword &&
        (keyword !== 'undefined' || typeof keyword === 'undefined')
      ) {
        this.isLoading = true
        request
          .get(`api/leads?search=${keyword}&per_page=10`)
          .then(({ data }) => {
            if (data.data) this.search_items = data.data
          })
          .finally(() => (this.isLoading = false))
      }
    },
    validEvent(showError) {
      if (typeof this.event === 'undefined') {
        return false
      }
      if (!this.event.start) {
        if (showError) this.appSnackbar('Start date required')
        return false
      }
      if (this.event.start === 'Invalid date') {
        if (showError) this.appSnackbar('Start is invalid date')
        return false
      }
      if (!this.event.end) {
        if (showError) this.appSnackbar('End date required')
        return false
      }
      if (this.event.end === 'Invalid date') {
        if (showError) this.appSnackbar('End is invalid date')
        return false
      }
      let start = moment(this.event.start, 'YYYY-MM-DD HH:mm:ss')
      let end = moment(this.event.end, 'YYYY-MM-DD HH:mm:ss')
      if (!start) {
        if (showError) this.appSnackbar('Start date is invalid')
        return false
      }
      if (!end) {
        if (showError) this.appSnackbar('End date is invalid')
        return false
      }
      if (start > end) {
        if (showError)
          this.appSnackbar(
            'End date and time should not be less than the start date and time '
          )
        return false
      }
      if (!this.event.recurrence && !this.isEdit) {
        if (showError) this.appSnackbar('Recurrence is required')
        return false
      }
      if (!this.event.title) {
        if (showError) this.appSnackbar('Title is required')
        return false
      }
      if (!this.event.event_type) {
        if (showError) this.appSnackbar('Event type is required')
        return false
      }
      if (this.event.link && !this.validateUrl(this.event.link)) {
        if (showError) this.appSnackbar('Event link is invalid')
        return false
      }
      return true
    },
    save() {
      if (this.validEvent(true)) {
        this.$emit(this.isEdit ? 'update' : 'save', this.getPayload)
      }
    },
    mapEvent(event) {
      this.event = {
        title: event.title,
        description: event.extendedProps.description,
        event_type: event.extendedProps.eventtypes_id,
        project: event.extendedProps.project_id,
        service: event.extendedProps.service_id,
        link: event.extendedProps.url,
        backgroundColor: event.backgroundColor,
        allDay: event.allDay,
        alarm: event.extendedProps.alarm,
        participants: event.extendedProps.participants.map((item) => {
          return item.id
        }),
        leads: event.extendedProps.leads.map((item) => {
          return item.id
        }),
        start: moment(event.start, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:00'
        ),
        end: moment(event.end, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYY-MM-DD HH:mm:00'
        ),
        recurrence: event.extendedProps.recurrence,
        timezone: event.extendedProps.timezone,
        description: event.extendedProps.description
      }
    },
    resetEvent() {
      this.event = _.cloneDeep(this.default)
    }
  }
}
</script>

<style lang="scss" scoped></style>
