<template>
  <div class="calendar-sidebar">
    <div>
      <v-card-text>
        <div class="add-event">
          <v-btn
            class="text-none"
            block
            dark
            height="40"
            color="primary"
            @click="$emit('add-new-event')"
          >
            Add Event
          </v-btn>
        </div>
        <div class="drag-n-drop-desc">
          <p class="vc-text-gray-500">
            Drag and drop your event or click in the calendar
          </p>
        </div>
        <div class="event-types" id="event-types">
          <v-list>
            <v-list-item
              v-for="category in event_types"
              :key="category.id"
              @mouseleave="category.hover = false"
              @mouseover="category.hover = true"
              ref="category.id"
              class="drag-el"
            >
              <v-btn
                :class="'fc-event ' + category.id"
                depressed
                dark
                class="text-left text-none drag-button"
                :color="category.properties.color"
                :data="category.id"
              >
                <div class="text-left darken-5">
                  {{ category.name | ucwords }}
                </div>
                <v-spacer />
                <v-icon color="whitesmoke" right>mdi-drag-vertical</v-icon>
              </v-btn>
              <v-list-item-action>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      v-show="category.hover && can_delete_event_type(category)"
                    >
                      <v-icon color="primary">
                        mdi-dots-vertical-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="delete_event_type(category)">
                      <v-list-item-title class="primary--text">
                        <v-icon color="primary" left>
mdi-delete-circle-outline
</v-icon>
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="edit_event_type(category)">
                      <v-list-item-title class="primary--text">
                        <v-icon color="primary" left>
mdi-circle-edit-outline
</v-icon>
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-btn
                depressed
                color="primary"
                class="text-none light--text no-drag-button"
                @click="$emit('add-new-event-type')"
              >
                New event type
                <v-spacer />
                <v-icon right>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </div>
        <!-- <v-checkbox dense v-model="weekendsVisibleCheckbox" label="Toggle Weekends" /> -->
        <div class="calendar-sidebar">
          <v-avatar height="200" width="100%" tile>
            <v-img
              :lazy-src="settings.loader"
              contain
              :src="calendar_image"
            ></v-img>
          </v-avatar>
        </div>
      </v-card-text>
    </div>
    <DeleteDialog
      :open-dialog.sync="event_type_delete_dialog"
      title="Delete Event Type"
      ref="delete_event_type_dialog"
      text-content="Are you sure you want to delete this event type?"
      @delete="closeDialogAndDeleteEventType"
    />
    <EventCategoryDialog
      v-model="event_type_dialog"
      :is-edit-dialog="true"
      :edit-type="activeType"
      @update="updateType"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import DeleteDialog from '@/common/DeleteDialog.vue'
import EventCategoryDialog from './components/EventCategoryDialog'

export default {
  name: 'CalendarSideBar',
  components: {
    EventCategoryDialog,
    DeleteDialog
  },
  props: {
    weekendsVisible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    event_types: [],
    activeType: null,
    event_type_delete_dialog: false,
    event_type_dialog: false,
    calendar_image: require('@/assets/svgs/images/calendar.svg').default
  }),
  watch: {
    categories(val) {
      this.event_types = val ? _cloneDeep(val) : []
    }
  },
  mounted() {
    /*this.$event.$on("calendar-view-changed", (filter) => {
     const calendar = this.$refs.vccalendar;
                if (calendar)
                    calendar.showPageRange(moment(filter.from))
    });*/
  },
  computed: {
    ...mapGetters('calendar', ['categories']),
    ...mapGetters(['user']),
    weekendsVisibleCheckbox: {
      get() {
        return this.weekendsVisible
      },
      set(value) {
        return this.$emit('set-weekends-visible', value)
      }
    },
    moment() {
      return moment
    }
  },
  methods: {
    ...mapActions('calendar', ['deleteEventType', 'updateEventType']),
    can_delete_event_type(eventType) {
      if (eventType.created_by === this.user.id) return true
      return this.user.is_admin || this.user.is_manager
    },
    delete_event_type(category) {
      this.activeType = category
      this.event_type_delete_dialog = true
    },
    edit_event_type(category) {
      this.activeType = category
      this.event_type_dialog = true
    },
    updateType(payload) {
      this.updateEventType(payload).then(() => {
        this.activeType = null
        this.event_type_dialog = false
        this.appSnackbar('Event types updated!')
      })
    },
    closeDialogAndDeleteEventType() {
      this.deleteEventType(this.activeType.id).then(() => {
        this.activeType = null
        this.event_type_delete_dialog = false
        this.appSnackbar('Event types deleted!')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.calendar-sidebar {
  margin-top: 10px;
}
.add-event {
  width: 90%;
  margin: auto;
  .v-btn {
    border-radius: 10px;
  }
}
.drag-n-drop-desc {
  margin-top: 40px;
  width: 100%;
  margin-bottom: 12px;
  color: #fff !important;
  p {
    color: #223a6b;
    font-weight: 700;
  }
}
.calendar-sidebar {
  line-height: 1.5;
}

.left .vc-border {
  border-width: 0px;
}

.v-application p {
  margin-left: 16px;
}

.v-application .px-3 {
  border: none;
  border-radius: 0;
  margin-top: 20px;
}
.event-types {
  .drag-button {
    width: 92%;
    cursor: move;
    .text-left {
      text-shadow: -1px 1px #aaa4a4;
    }
  }
  .no-drag-button {
    width: 92%;
  }
}
</style>
