import '@fullcalendar/vue/dist/main'
import CalendarSidebar from './CalendarSidebar.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'

import EventRightSidebar from './components/EventRightSidebar.vue'
import ParticipantsDialog from './components/ParticipantsDialog.vue'
import AddParticipantDialog from './components/AddParticipantDialog/AddParticipantDialog.vue'
import EventCategoryDialog from './components/EventCategoryDialog.vue'
import DateMenu from '@/common/Menus/DateMenu.vue'
import EventCategoryMenu from '@/common/Menus/EventCategoryMenu.vue'
import RecurrenceUpdateDialog from './components/RecurrenceUpdateDialog.vue'
import RecurrenceDeleteDialog from './components/RecurrenceDeleteDialog.vue'
import RecurrenceLeaveDialog from './components/RecurrenceLeaveDialog.vue'
import CalendarPopupView from './components/CalendarPopupView.vue'
import EachEvent from './components/EachEvent.vue'

import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Calendar',
    components: {
        RecurrenceUpdateDialog,
        RecurrenceDeleteDialog,
        RecurrenceLeaveDialog,
        EventCategoryDialog,
        ParticipantsDialog,
        FullCalendar,
        Draggable,
        CalendarPopupView,
        EachEvent,
        CalendarSidebar,
        AddParticipantDialog,
        DateMenu,
        EventCategoryMenu,
        EventRightSidebar
    },
    data: () => ({
        calendar: null,
        allow_create_on_past_dates: false,
        event_dialog: false,
        update_recurrence_by_dialog: false,
        update_recurrence_by_participants: false,
        event_delete_recurrence_dialog: false,
        event_leave_recurrence_dialog: false,
        event_participant_dialog: false,
        event_type_dialog: false,
        is_edit_event: false,
        eventToEdit: null,
        event_payload: null,
        activeEvent: null,
        event_filters: [],
        filters: null,
        currentDate: '',
        postX: null,
        postY: null,
        selectedOpen: '',
        drag_event: {},
        selectedView: 'dayGridMonth',
        dropdown: [
            { title: 'Day', value: 'timeGridDay' },
            { title: 'Week', value: 'timeGridWeek' },
            { title: 'Month', value: 'dayGridMonth' }
        ],
        update_recurrence_dialog: false,
        select_date: null,
        pending_update_payload: null
    }),
    computed: {
        ...mapGetters(['user']),
        ...mapGetters('calendar', [
            'events',
            'fetching',
            'categories',
            'weekendsVisible',
            'projects'
        ]),
        is_creator() {
            return (
                this.activeEvent &&
                this.activeEvent.extendedProps.creator == this.user.id
            )
        },
        calendarApi() {
            return this.$refs.calendar ? this.$refs.calendar.getApi() : null
        },
        filtered_events() {
            let categories = this.categories.map((i) => i.id)
            return _cloneDeep(this.events).filter((e) => {
                let event_type_id = parseInt(e.extendedProps.eventtypes_id)
                return (
                    this.event_filters.includes(event_type_id) ||
                    !categories.includes(event_type_id)
                )
            })
        },
        hasCloseButton() {
            return this.$route.query.source && ['dashboard', 'project-preview'].includes(this.$route.query.source) ?
                true :
                false
        },
        configCalendar() {
            const vm = this
            let config = {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin // needed for dateClick
                ],
                expandRows: true,
                initialView: 'dayGridMonth',
                editable: true,
                selectable: true,
                weekends: this.weekendsVisible,
                droppable: true,
                headerToolbar: false,
                selectMirror: false,
                dayMaxEvents: true,
                eventTimeFormat: {
                    // like '14:30:'
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: true
                },
                events: vm.filtered_events,
                businessHours: {
                    // days of week. an array of zero-based day of week integers (0=Sunday)
                    daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
                    startTime: '07:00', // a start time (7am in this example)
                    endTime: '22:00' // an end time (6pm in this example)
                },
                select: vm.onDateSelect,
                dateClick: vm.onDateClick,
                eventClick: vm.onEventClick,
                eventDrop: vm.onEventDrop,
                eventResize: vm.onEventDrop,
                eventReceive: vm.onEventReceive,
                drop: vm.getDragEvent,
                selectAllow: function(selectInfo) {
                    if (vm.allow_create_on_past_dates) return true
                    let can =
                        moment().isSame(moment(selectInfo.start), 'day') ||
                        moment(selectInfo.start).diff(moment()) > 0
                    if (!can) vm.appSnackbar('Cannot add event on past dates', 'error')
                    return can
                },
                eventAllow: function(dropLocation, draggedEvent) {
                    if (
                        vm.allow_create_on_past_dates ||
                        moment().isSame(moment(dropLocation.start), 'day') ||
                        moment(dropLocation.start).diff(moment()) > 0
                    ) {
                        if (draggedEvent.hasOwnProperty('extendedProps')) {
                            let can = draggedEvent.extendedProps.creator === vm.user.id // or return false to disallow
                            if (!can)
                                vm.appSnackbar(
                                    'You dont have permissions to update this event',
                                    'error'
                                )
                            return can
                        } else return true
                    }
                }
            }
            if (!this.mdAndUp) config.height = 'auto'
            return config
        }
    },
    watch: {
        select_date(val) {
            if (val && this.calendarApi) {
                this.calendarApi.gotoDate(val)
                this.calendarApi.changeView('timeGridDay')
                this.selectedView = 'timeGridDay'
                this.currentDate = this.calendarApi.currentData.viewTitle
            }
        },
        categories: {
            handler: function(val) {
                this.event_filters = val ? val.map((i) => i.id) : []
            },
            immediate: true,
            deep: true
        },
        filters: {
            handler(val) {
                if (val) {
                    this.filterEvents(val)
                    this.$event.$emit('calendar-view-changed', val)
                }
            },
            immediate: true,
            deep: true
        },
        selectedView(val) {
            this.setView(val)
        }
    },
    created() {
        this.fetchCategories()
        this.fetchProjectList()
        this.fetchServiceList()
    },
    mounted() {
        if (this.calendarApi) {
            this.currentDate = this.calendarApi.currentData.viewTitle
            this.setCurrentRange(this.calendarApi)
            this.setupDraggable()
        } else {
            console.error('Cannot find calendar reference!')
        }
    },
    methods: {
        ...mapActions('calendar', [
            'createEvent',
            'updateByModalEvent',
            'updateByDragEvent',
            'deleteEvent',
            'leaveEvent',
            'setWeekendsVisible',
            'filterEvents',
            'fetchCategories',
            'fetchProjectList',
            'fetchServiceList',
            'manageParticipants',
            'saveEventTypes',
            'toggleRecurrenceStatus'
        ]),
        reloadCalendar() {
            this.calendarApi.destroy()
            this.filterEvents(this.filters).then(() => {
                this.calendarApi.render()
            })
        },
        openAddParticipantDialog(event) {
            this.activeEvent = null
            this.event_participant_dialog = true
            this.selectedOpen = false
            this.$nextTick(() => {
                this.activeEvent = _cloneDeep(event)
            })
        },
        setupDraggable() {
            new Draggable(document.getElementById('event-types'), {
                itemSelector: '.fc-event',
                eventData: function(eventEl) {
                    let drag_event = {
                        title: eventEl.innerText,
                        id: eventEl.getAttribute('data')
                    }
                    return drag_event
                }
            })
        },
        getEventbyId(id) {
            return this.events.find((item) => parseInt(item.id) == parseInt(id))
        },
        getDragEvent(event) {
            let e = {
                startStr: event.dateStr,
                start: event.date,
                endStr: event.dateStr,
                end: event.date,
                allDay: event.allDay,
                MouseEvent: event.jsEvent,
                view: event.view,
                extendedProps: {
                    eventtypes_id: parseInt(
                        event.draggedEl.classList[event.draggedEl.classList.length - 1]
                    )
                }
            }
            this.onNewEvent()
            this.event_payload = null
            setTimeout(() => {
                this.event_payload = e
            }, 5)
            this.calendarApi.refetchEvents()
        },
        dayClicked(day) {
            if (this.calendarApi) {
                this.calendarApi.gotoDate(day.id)
                this.calendarApi.changeView('timeGridDay')
                this.selectedView = 'timeGridDay'
                this.currentDate = this.calendarApi.currentData.viewTitle
            }
        },
        setCurrentRange(calendarApi) {
            let range = calendarApi.getCurrentData().dateProfile.activeRange
            if (range) {
                this.filters = {
                    from: moment(range.start).format('YYYY-MM-DD HH:mm:ss'),
                    to: moment(range.end).format('YYYY-MM-DD HH:mm:ss'),
                    timezone: moment.tz.guess() || 'UTC'
                }
            }
        },
        setView(view) {
            if (this.calendarApi) {
                this.calendarApi.changeView(view)
                this.currentDate = this.calendarApi.currentData.viewTitle
                this.setCurrentRange(this.calendarApi)
            }
        },
        setToday() {
            if (this.calendarApi) {
                this.calendarApi.today()
                this.currentDate = this.calendarApi.currentData.viewTitle
                this.setCurrentRange(this.calendarApi)
            }
        },
        prev() {
            if (this.calendarApi) {
                this.calendarApi.prev()
                this.currentDate = this.calendarApi.currentData.viewTitle
                this.setCurrentRange(this.calendarApi)
            }
        },
        next() {
            if (this.calendarApi) {
                this.calendarApi.next()
                this.currentDate = this.calendarApi.currentData.viewTitle
                this.setCurrentRange(this.calendarApi)
            }
        },
        onNewEvent() {
            this.event_dialog = true
            this.activeEvent = null
            this.is_edit_event = false
        },
        onDateClick(payload) {
            payload.jsEvent.preventDefault()
            if (
                moment().isSame(moment(payload.date), 'day') ||
                moment(payload.date).diff(moment()) > 0
            ) {
                this.event_payload = null
                setTimeout(() => {
                    this.event_payload = payload
                    this.onNewEvent()
                }, 5)
            }
        },
        onDateSelect(payload) {
            payload.jsEvent.preventDefault()
            this.event_payload = null
            setTimeout(() => {
                this.event_payload = payload
                this.onNewEvent()
            }, 5)
        },
        onEventClick(e) {
            this.selectedOpen = false
            e.jsEvent.preventDefault()
            if (e.event) {
                this.activeEvent = this.getEventbyId(e.event._def.publicId)
                this.$nextTick(() => {
                    this.postX = e.jsEvent.clientX
                    this.postY = e.jsEvent.clientY
                    this.selectedOpen = true
                })
            }
        },
        onEventReceive(info) {
            info.revert() // revert after drop , let save event put the evnet to display
        },
        onEventDrop({ event }) {
            if (
                event &&
                event._def.extendedProps.recurrence &&
                event._def.extendedProps.recurrence !== 'none'
            ) {
                this.drag_event = event
                this.update_recurrence_dialog = true
            } else {
                this.updateByDragEvent(event).then(() => {
                    this.appSnackbar('Event updated!')
                })
            }
        },
        handleEventDrop(event) {
            let payload = this.drag_event
            payload.recurrence_affected = event
            this.updateByDragEvent(payload).then(() => {
                this.update_recurrence_dialog = false
                this.appSnackbar('Event updated!')
            })
        },
        handleSaveEvent(payload) {
            this.createEvent(payload).then(() => {
                this.event_dialog = false
                this.appSnackbar('Event created!')
            })
        },
        showEditEvent(event) {
            this.activeEvent = null
            this.is_edit_event = true
            this.event_dialog = true
            this.selectedOpen = false
            this.$nextTick(() => {
                this.activeEvent = _cloneDeep(event)
            })
        },
        handleUpdateEvent(payload) {
            if (this.activeEvent.extendedProps.recurrence != 'none') {
                this.update_recurrence_by_dialog = true
                this.pending_update_payload = payload
            } else {
                this.updateByModalEvent(payload).then(() => {
                    this.event_dialog = false
                    this.appSnackbar('Event updated!')
                })
            }
        },
        confirmedUpdate(action) {
            let payload = Object.assign({}, this.pending_update_payload, {
                action: action
            })
            this.updateByModalEvent(payload).then(() => {
                this.event_dialog = false
                this.update_recurrence_by_dialog = false
                this.pending_update_payload = null
                this.appSnackbar('Events updated!')
                this.reloadCalendar()
            })
        },
        showLeaveEvent(event) {
            this.activeEvent = event
            this.selectedOpen = false
            if (event.extendedProps.recurrence != 'none') {
                this.event_leave_recurrence_dialog = true
            } else {
                this.appConfirmation('Leave this event?', () => {
                    this.confirmedLeave(this.activeEvent, null)
                })
            }
        },
        confirmedLeave(event, action) {
            this.leaveEvent({
                id: event.id,
                action: action,
                cb: () => {
                    this.event_leave_recurrence_dialog = false
                    this.appSnackbar('Succesfully leave event')
                    this.reloadCalendar()
                }
            })
        },
        showCreateEventType() {
            this.event_type_dialog = true
        },
        showDeleteEvent(event) {
            this.activeEvent = event
            this.selectedOpen = false
            if (event.extendedProps.recurrence != 'none') {
                this.event_delete_recurrence_dialog = true
            } else {
                this.appConfirmation(
                    'Are you sure you want to delete this event?',
                    () => {
                        this.confirmedDelete('this_event')
                    }
                )
            }
        },
        confirmedDelete(action) {
            this.deleteEvent({ id: this.activeEvent.id, action: action }).then(() => {
                this.event_delete_recurrence_dialog = false
                this.activeEvent = null
                this.appSnackbar('Event deleted!')
                this.reloadCalendar()
            })
        },
        handleManageParticipants(payload) {
            let data = {
                participants: payload.participants,
                id: this.activeEvent.id
            }
            if (this.activeEvent.extendedProps.recurrence != 'none') {
                this.update_recurrence_by_participants = true
                this.event_participant_dialog = false
                this.pending_update_payload = data
            } else {
                this.manageParticipants({
                    payload: data,
                    cb: () => {
                        this.activeEvent = null
                        this.update_recurrence_by_participants = false
                        this.pending_update_payload = null
                        this.appSnackbar('Event participants updated!')
                        this.reloadCalendar()
                    }
                })
            }
        },
        confirmedUpdateParticipants(action) {
            this.manageParticipants({
                payload: Object.assign({}, { action: action },
                    this.pending_update_payload
                ),
                cb: () => {
                    this.event_participant_dialog = false
                    this.activeEvent = null
                    this.update_recurrence_by_participants = false
                    this.pending_update_payload = null
                    this.appSnackbar('Event participants updated!')
                    this.reloadCalendar()
                }
            })
        },
        handleCreatedEventTypes(payload) {
            this.saveEventTypes(payload).then(() => {
                this.event_type_dialog = false
                this.appSnackbar('Event types created!')
            })
        },
        confirmRecurrenceStatus(event, action) {
            this.appConfirmation(
                action == 'terminate' ?
                'Terminate this event recurrence?' :
                'Resume this event recurrence?',
                () => {
                    this.toggleRecurrenceStatus({
                        id: event.id,
                        action: action,
                        cb: () => {
                            this.selectedOpen = false
                            this.appSnackbar('Events recurrence updated')
                            this.reloadCalendar()
                        }
                    })
                }
            )
        }
    }
}