<template>
  <CustomDialog
    :title="dialogTitle"
    ref="dialog"
    :open.sync="open"
    button2-text="Save"
    @button1="cancel"
    @button2="save"
    @click-close="cancel"
    :max-width="700"
    :main-btn-disabled="can_save === false"
  >
    <template slot="content">
      <v-card flat>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6">
              <v-list dense max-height="400" style="overflow-y: auto">
                <v-subheader class="subtitle-2">
                  Members ({{ list.length }})
                </v-subheader>
                <template v-if="available.length > 0">
                  <v-list-item
                    v-for="(user, index) in available"
                    :key="index"
                    @click="add_to(user, index)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="user.image_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ user.fullname | ucwords }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ user.job_title | ucwords }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-account-arrow-right-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list dense max-height="400" style="overflow-y: auto">
                <v-subheader class="subtitle-2">
                  Selected ({{ selected.length }})
                </v-subheader>
                <template v-if="selected.length > 0">
                  <v-list-item
                    v-for="(user, index) in selected"
                    :key="index"
                    @click="remove_from(user, index)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        :src="user.image_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ user.fullname | ucwords }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ user.job_title | ucwords }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-account-arrow-left-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import _diffBy from 'lodash/differenceBy'

export default {
  name: 'ParticipantsDialog',
  components: {
    CustomDialog
  },
  data: () => ({
    open: false,
    list: [],
    selected: []
  }),
  props: {
    dialog: Boolean,
    defaults: Array,
    dialogTitle: { type: String, default: 'Manage Participants' }
  },
  watch: {
    dialog(new_val) {
      this.open = new_val
    },
    open(new_val) {
      this.$emit('update:dialog', new_val)
    },
    defaults: {
      handler(val) {
        if (val) {
          this.selected = _cloneDeep(val)
        }
      },
      deep: true,
      immediate: true
    },
    users: {
      handler(val) {
        if (val) {
          this.list = _cloneDeep(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user', 'users']),
    available() {
      return _diffBy(this.list, this.selected, 'id')
    },
    can_save() {
      return this.selected.length > 0
    }
  },
  methods: {
    cancel() {
      this.$emit('close')
      this.open = false
    },
    save() {
      let payload = {
        participants: this.selected.map((user) => {
          return user.id
        })
      }
      this.$emit('save', payload)
    },
    add_to(item, index) {
      this.selected.push(item)
    },
    remove_from(item, index) {
      this.selected.splice(index, 1)
    }
  }
}
</script>

<style scoped></style>
