<template>
  <CustomDialog
    title="Delete recurring event"
    v-model="open"
    button2-text="Delete"
    @button1="cancel"
    @button2="save"
    @click-close="cancel"
  >
    <template slot="content">
      <v-card flat class="ma-1">
        <v-card-text>
          <label class="subtitle-2 primary--text">
            This event is part of a recurrence event. What action should we do?
          </label>
          <v-radio-group class="radio-primary--text" v-model="selected">
            <v-radio
              v-for="choice in choices"
              :key="choice.value"
              :label="choice.text"
              :value="choice.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'RecurrenceDeleteDialog',
  components: { CustomDialog },
  data: () => ({
    open: false,
    selected: 'this_event',
    choices: [
      {
        text: 'Delete only on this event',
        value: 'this_event'
      },
      {
        text: 'Delete this event and all succeeding events',
        value: 'this_event_and_next'
      }
    ]
  }),
  props: {
    value: { type: Boolean, default: false }
  },
  watch: {
    value: {
      handler: function (new_val) {
        this.open = new_val
      },
      immediate: true
    },
    open(new_val) {
      this.$emit('input', new_val)
    }
  },
  methods: {
    save() {
      this.$emit('action', this.selected)
    },
    cancel() {
      this.open = false
      this.$emit('cancelled', true)
    }
  }
}
</script>

<style scoped></style>
