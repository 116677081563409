<template>
  <div>
    <cMenu v-bind="$attrs" :close-on-content-click="false" :valid="isValid">
      <v-list dense min-width="200" :max-height="400" style="overflow-y: auto">
        <v-list-item @click.stop="$emit('add-new')" v-if="withCreate" class="primary mt-n2 py-2">
          <v-list-item-icon class=" mr-2">
            <v-icon color="white">mdi-plus-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">Add new event type </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group
          v-model="current"
          :multiple="true"
          :mandatory="false"
          v-if="mappedList.length"
        >
          <template v-for="(item, index) in mappedList">
            <v-list-item
              :key="index"
              :value="item.id"
              active-class="primary--text"
            >
              <v-list-item-action class="mr-2">
                <v-checkbox
                  off-icon="mdi-checkbox-blank"
                  on-icon="mdi-checkbox-marked"
                  :color="item.color"
                  hide-details="auto"
                  :input-value="isSelected(item.id)"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name | ucwords }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
        <v-list-item v-else>
          <v-list-item-title>No available selection list</v-list-item-title>
        </v-list-item>
      </v-list>
    </cMenu>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  inheritAttrs: false,
  name: "EventCategoryMenu",
  props: {
    list: { type: Array, required: true },
    value: Array,
    withCreate: { type: Boolean, default: true },
  },
  data: () => ({
    current: [],
  }),
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.current = val;
        } else this.current = [];
      },
      deep: true,
      immediate: true,
    },
    current: {
      handler: function (val) {
        this.$emit("input", val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isValid() {
      return true;
    },
    mappedList() {
      return _.cloneDeep(this.list);
    },
  },
  methods: {
    isSelected(id) {
      let index = this.current.findIndex((i) => i === id);
      return ~index;
    },
  },
};
</script>

<style>
</style>