<template>
  <div>
    <cMenu
      v-bind="$attrs"
      :close-on-content-click="closeOnSelect"
      :valid="isValid"
    >
      <v-card flat>
        <v-date-picker
          header-color="primary"
          v-model="current"
          :min="min"
          :max="max"
        ></v-date-picker>
      </v-card>
    </cMenu>
    <template v-if="hasPreview">
      <div class="fullwidth mt-1" v-if="current">
        <v-chip large class="fullwidth" label>
          {{ current | format(displayFormat) }}
          <v-icon v-if="!required" @click="clear" right class="always-right">
            mdi-close-circle-outline
          </v-icon>
        </v-chip>
      </div>
      <div class="fullwidth mt-1" v-else>
        <v-chip large class="fullwidth" label>Unset</v-chip>
      </div>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  components: {},
  inheritAttrs: false,
  name: "DateMenu",
  props: {
    value: { type: [String, Date] },
    min: { type: [String, Date] },
    max: { type: [String, Date] },
    displayFormat: { type: String, default: "LL" },
    hasPreview: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: true },
  },
  data: () => ({
    current: null,
  }),
  watch: {
    value: {
      handler: function (val) {
        this.current = val;
      },
      deep: true,
      immediate: true,
    },
    current: {
      handler: function (val) {
        this.$emit("input", val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isValid() {
      if (!this.required) {
        return true;
      } else return this.current ? true : false;
    },
  },
  methods: {
    clear() {
      this.current = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.fullwidth {
  .always-right {
    position: absolute !important;
    right: 15px !important;
  }
}
</style>